import { GetServerSidePropsContext } from "next";
import nookies from "nookies";

const setCookie = (key: string, value: string) => {
  nookies.set(undefined, key, value, { path: "/" });
};

const getCookie = (context: GetServerSidePropsContext) => {
  return nookies.get(context);
};

const userTokenName = "__tloukneenr";

const setUserToken = (token: string) => {
  setCookie(userTokenName, token);
};

const getUserToken = (context: GetServerSidePropsContext) => {
  // const cookie = getCookie(context);
  // return cookie[userTokenName];
  return context.req.cookies[userTokenName];
};

export { setCookie, getCookie, setUserToken, getUserToken };
