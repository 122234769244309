import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@lib/themes";
import NumberFormat from "@components/NumberFormat";
import { ITheme } from "@lib/themes/types";
import { DEFAULT_CURRENCIES } from "@lib/const";
import BitcoinIcon from "@public/bitcoin-icon.svg";
import EthereumIcon from "@public/ethereum-icon.svg";
import OtherIcon from "@public/other-coins.svg";
import React from "react";
import NextLink from "next/link";
import { appScrollBar } from "@lib/utils/cssUtils";
import trackEvent, {
  GAEventAction,
  GAEventCategory,
  GAEventLabel,
} from "@lib/ga";

const useStyles = makeStyles((theme: ITheme) => ({
  wrapper: {
    borderBottom: `1px solid ${theme.palette.header.divider}`,
    borderTop: `1px solid ${theme.palette.header.divider}`,
  },
  container: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    // borderBottom: `1px solid ${theme.palette.divider}`,
    // borderTop: `1px solid ${theme.palette.divider}`,
    height: 39,
    whiteSpace: "nowrap",
    overflowX: "auto",

    [theme.breakpoints.up("md")]: {
      // justifyContent: "center",
      height: 48,
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      width: "100%",
      maxWidth: 1360,
      marginLeft: "auto",
      marginRight: "auto",
      ...appScrollBar(theme),
    },
    [theme.breakpoints.down("md")]: {
      marginRight: theme.spacing(3),
    },
    "@media(min-width: 1400px)": {
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),
      maxWidth: 1440,
    },
  },
  title: {
    color: theme.palette.text.primary,
    marginRight: theme.spacing(1),
  },
  value: {
    marginRight: theme.spacing(3),
  },
  mainTitle: {
    color: theme.palette.dataHeader.text,
  },
  icon: {
    "& path": {
      fill: theme.palette.dataHeader.icon,
    },
  },
  titleCard: {
    background: theme.palette.dataHeader.background,
    borderRadius: 6,
    padding: "1px 8px",
    marginRight: 8,
  },
  pointer: {
    cursor: "pointer",
  },
}));

export function DataHeader({ header }) {
  const classes = useStyles();

  return (
    <Box position="static" className={classes.wrapper}>
      <Box className={classes.container}>
        <Box className={classes.titleCard}>
          <Typography className={classes.mainTitle} variant="body2">
            Market Cap:
          </Typography>
        </Box>
        <Typography variant="body2">
          <NumberFormat
            value={header.marketcap_luner_active}
            currency={DEFAULT_CURRENCIES.USD.slug}
            format=",d"
          />
        </Typography>
        <NumberFormat
          dynamicBoxProps={{ ml: 1, mr: 3 }}
          value={header.marketcap_change_luner_active}
          dynamic={header.marketcap_change_luner_active}
          suffix=" / 24h"
          format="percent"
        />
        {/* <Typography className={classes.title} variant="body2">
          TVL:
        </Typography>
        <Typography>
          <NumberFormat
            value={header.total_tvl_now}
            currency={DEFAULT_CURRENCIES.USD.slug}
            format=",d"
          />
        </Typography>
        <NumberFormat
          dynamicBoxProps={{ ml: 1, mr: 3 }}
          value={header.total_tvl_daily_change}
          dynamic={header.total_tvl_daily_change}
          suffix=" / 24h"
          format="percent"
        /> */}
        <NextLink href="/labs/dominance">
          <Box className={`${classes.titleCard} ${classes.pointer}`}>
            <Typography
              className={classes.mainTitle}
              variant="body2"
              onClick={() =>
                trackEvent({
                  action: GAEventAction.Dominance,
                  category: GAEventCategory.Header,
                  label: GAEventLabel.Dominance,
                })
              }>
              Dominance:
            </Typography>
          </Box>
        </NextLink>

        <Box display="flex">
          <BitcoinIcon className={classes.icon} />
          <Typography className={classes.title} variant="body2">
            BTC:
          </Typography>
        </Box>
        <Typography variant="body2" className={classes.value}>
          <NumberFormat value={header.dominance_btc} format="percent" />
        </Typography>

        <Box display="flex">
          <EthereumIcon className={classes.icon} />
          <Typography className={classes.title} variant="body2">
            ETH:
          </Typography>
        </Box>
        <Typography variant="body2" className={classes.value}>
          <NumberFormat value={header.dominance_eth} format="percent" />
        </Typography>

        <Box display="flex">
          <OtherIcon className={classes.icon} />
          <Typography className={classes.title} variant="body2">
            Others:
          </Typography>
        </Box>
        <Typography variant="body2">
          <NumberFormat value={header.dominance_others} format="percent" />
        </Typography>
      </Box>
    </Box>
  );
}
