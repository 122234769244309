import React from "react";
import { makeStyles } from "@lib/themes";
import { Box, Slide } from "@material-ui/core";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import { useScrollPosition } from "@lib/hooks/useScrollPosition";
import MainButton from "@components/MainButton";

const useStyles = makeStyles((theme) => ({
  topButton: {
    position: "fixed",
    right: 80,
    bottom: 40,
    display: "flex",
    zIndex: 3,
    [theme.breakpoints.down("sm")]: {
      right: 16,
      bottom: 24,
      ".MuiButton-root": {
        padding: "13px",
      },
      ".MuiButton-endIcon": {
        marginLeft: 0,
      },
      "& p": {
        display: "none",
      },
    },
    ".MuiButton-root": {
      minWidth: "0",
    },
  },
}));

const TopButton = () => {
  const classes = useStyles();
  const { scrollPosition } = useScrollPosition();

  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <Slide direction="up" in={scrollPosition > 200} mountOnEnter unmountOnExit>
      <Box className={classes.topButton}>
        <MainButton
          title="Top"
          variant="secondary"
          endIcon={<KeyboardArrowUp />}
          onClick={scrollTop}
        />
      </Box>
    </Slide>
  );
};

export default TopButton;
