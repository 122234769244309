import clsx from "clsx";
import NextLink from "next/link";
import { useRouter } from "next/router";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Drawer,
  IconButton,
  Link,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@lib/themes";
import ExitIcon from "@material-ui/icons/ExitToApp";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import ThemeSwitch from "@components/ThemeSwitch";
import { ITheme } from "@lib/themes/types";
// import { useAppTheme } from "./use-app-theme"
// import { lightTheme, darkTheme } from "@lib/themes"
import { NAVIGATION, NavigationItem, ParentNavigationItem } from "@lib/const";
import { useAuth } from "./useAuth";
import trackEvent, {
  GAEventAction,
  GAEventCategory,
  GAEventLabel,
} from "@lib/ga";

const useStyles = makeStyles((theme: ITheme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    overflowY: "auto",
    overflowX: "hidden",
    padding: theme.spacing(4),
    paddingTop: 120,
    background: theme.palette.mobileMenu.background,

    "& > label": {
      marginLeft: 0,
    },

    "& .MuiAccordion-root": {
      margin: 0,
    },

    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
  },
  userRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: 72,
    textTransform: "capitalize",
  },
  userBox: {
    display: "flex",
    alignItems: "center",
  },
  userAvatar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.avatarBackground,
  },
  userName: {
    paddingLeft: 0,
    textTransform: "none",
  },
  logoutButton: {
    marginRight: -13,
  },
  groupItem: {
    justifyContent: "flex-start",
    height: 72,
    padding: 0,
    // borderBottom: `1px solid ${theme.palette.divider}`,
    background: theme.palette.mobileMenu.background,

    "& .MuiAccordionSummary-content": {
      flexGrow: 0,
    },
  },
  itemsList: {
    flexDirection: "column",
    padding: 0,
    background: theme.palette.mobileMenu.background,
  },
  item: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    minHeight: 72,
    paddingLeft: theme.spacing(3),
    color: theme.palette.text.primary,
    // borderBottom: `1px solid ${theme.palette.divider}`,
  },
  parentItem: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    minHeight: 72,
    color: theme.palette.text.primary,
    // borderBottom: `1px solid ${theme.palette.divider}`,
  },
  selectedItem: {
    color: theme.palette.mobileMenu.selected,
  },
  switch: {
    alignSelf: "flex-start",
    marginRight: "auto",
    marginTop: 26,
    marginBottom: 26,
  },
  loginButton: {
    marginTop: "auto",
  },
  accordionRoot: {
    "&::before": {
      height: 0,
    },
  },
}));

export function MobileMenu({
  close,
  opened,
  onLogout,
}: {
  opened: boolean;
  close: () => void;
  onLogout?: () => void;
}) {
  const classes = useStyles();
  // const { theme, setTheme } = useAppTheme()
  const { user } = useAuth();
  const router = useRouter();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // const switchTheme = () => {
  //   setTheme(theme.palette.type === "light" ? darkTheme : lightTheme)
  // }

  // TODO: sync avatar loading

  return (
    <Drawer anchor="top" open={opened} style={{ zIndex: 1200 }}>
      <Box className={classes.content}>
        {user && (
          <Box className={classes.userRow}>
            <Box className={classes.userBox}>
              <Typography className={classes.userName}>
                {user && user.firstName && user.firstName.length > 0
                  ? user.firstName
                  : user.email}
              </Typography>
            </Box>
            <IconButton
              color="default"
              aria-label="menu"
              onClick={onLogout}
              className={classes.logoutButton}>
              <ExitIcon />
            </IconButton>
          </Box>
        )}
        {NAVIGATION.filter(
          (n) =>
            n.name !== "Privacy Policy" &&
            n.name !== "Terms Of Service" &&
            n.name !== "Login",
        ).map(({ name, ...item }) => {
          if ("path" in item === true) {
            const { path } = item as NavigationItem;
            return (
              <NextLink href={path} key={path}>
                <Link
                  href={path}
                  onClick={() => {
                    close();
                    trackEvent(item.gaEvent, isMobile);
                  }}
                  color="inherit"
                  variant="body1"
                  underline="none"
                  className={clsx(classes.parentItem, {
                    [classes.selectedItem]: path === router.pathname,
                  })}>
                  {name}
                </Link>
              </NextLink>
            );
          } else {
            const { items } = item as ParentNavigationItem;
            return (
              <Accordion
                key={name}
                classes={{ root: classes.accordionRoot }}
                onChange={(_, expanded) => {
                  if (expanded) {
                    trackEvent(
                      {
                        action: name,
                        category: GAEventCategory.Header,
                        label: items.map((i) => i.name).join(" \n"),
                      },
                      isMobile,
                    );
                  }
                }}>
                <AccordionSummary
                  className={classes.groupItem}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
                  <Typography>{name}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.itemsList}>
                  {items.map((item) => (
                    <NextLink href={item.path} key={item.path}>
                      <Link
                        onClick={() => {
                          close();
                          trackEvent(item.gaEvent, isMobile);
                        }}
                        color="inherit"
                        variant="body1"
                        underline="none"
                        href={item.path}
                        className={clsx(classes.item, {
                          [classes.selectedItem]: item.path === router.pathname,
                        })}>
                        {item.name}
                      </Link>
                    </NextLink>
                  ))}
                </AccordionDetails>
              </Accordion>
            );
          }
        })}
        <Box height={72} display="flex" alignItems="center">
          <ThemeSwitch />
        </Box>
        {!user && (
          <NextLink href="/login">
            <Button
              fullWidth
              size="large"
              type="button"
              color="primary"
              variant="contained"
              className={classes.loginButton}
              onClick={() => {
                close();
                trackEvent(
                  {
                    action: GAEventAction.Account,
                    category: GAEventCategory.Header,
                    label: GAEventLabel.SignIn,
                  },
                  isMobile,
                );
              }}>
              Log In
            </Button>
          </NextLink>
        )}
      </Box>
    </Drawer>
  );
}
