import { JsonMap } from "@lib/types";
import firebase from "firebase/app";
import "firebase/auth";

export interface UserFuncError extends JsonMap {
  code?: number | string;
  message?: string;
}

export type UserFunction = Promise<{
  data?: boolean | User;
  error?: UserFuncError;
}>;

export interface User {
  firstName: string;
  lastName: string;
  email: string;
  avatarUrl?: string;
  _id?: string;
}

export interface AuthProviderProps {
  children: React.ReactNode;
  initialUser: User | null;
}

export interface AuthContextInterface {
  isInitializing: boolean;
  user: User;
  firebaseUser: firebase.User | null;
  setUser: (user: User | null) => void;
  signOut: () => void;
  signIn(params: { email: string; password: string }): UserFunction;
  signInWithGoogle(): UserFunction;
  signInWithFacebook(): UserFunction;
  verifyEmail(params: { token: string }): UserFunction;
  resetPassword(params: { token: string; password: string }): UserFunction;
  changePassword(params: {
    oldPassword: string;
    newPassword: string;
  }): UserFunction;
  setNewPassword(params: { password: string }): UserFunction;
  updateProfile(params: { firstName: string; lastName: string }): UserFunction;
  hasAuthProvider(type: AuthProviderType): boolean | null;
  linkSocialMedia(type: AuthProviderType): UserFunction;
}

export declare type AuthProviderType =
  | "EMAIL_PASSWORD"
  | "GOOGLE"
  | "FACEBOOK"
  | "TWITTER"
  | "APPLE";

export interface UserData {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  avatarUrl?: string;
  invitesLeft: number;
  isAdmin: boolean;
  isTemporary: boolean;
}

export const defaultUserData = {
  invitesLeft: 20,
  isAdmin: false,
  isTemporary: false,
};
