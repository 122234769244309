import React from "react";
import {
  Menu as MenuBase,
  MenuProps as MenuBaseProps,
  MenuItem as MenuBaseItem,
} from "@material-ui/core";
import { makeStyles } from "@lib/themes";
import { ITheme } from "@lib/themes/types";

interface MenuProps extends MenuBaseProps {
  minWidth?: number;
}

const useMenuStyles = makeStyles((theme: ITheme, props) => ({
  paper: {
    minWidth: props.minWidth,
    marginTop: 4,
    border: `1px solid ${theme.palette.divider}`,
    background: theme.palette.contentBackground,
    boxShadow: "0px 24px 48px rgba(0, 0, 0, 0.06)",
  },
  list: {
    padding: 0,
    position: "relative",
    "& .MuiLink-root:not(:last-child)": {
      "& .MuiListItem-root:after": {
        content: "''",
        backgroundColor: theme.palette.divider,
        position: "absolute",
        left: theme.spacing(2),
        right: theme.spacing(2),
        bottom: 0,
        height: 1,
      },
    },
  },
}));

const useItemStyles = makeStyles((theme: ITheme) => ({
  root: {
    height: 48,
    "&.Mui-selected": {
      backgroundColor: theme.palette.menu.hover,
    },
    "&.MuiListItem-root.Mui-selected:hover": {
      backgroundColor: theme.palette.menu.hover,
    },
  },
}));

export function Menu({ minWidth, open, ...props }: MenuProps) {
  const classes = useMenuStyles({ minWidth, open });
  return (
    <MenuBase
      classes={classes}
      open={open}
      elevation={0}
      // getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      {...props}
    />
  );
}

export function MenuItem(props) {
  const classes = useItemStyles();
  return <MenuBaseItem classes={classes} {...props} />;
}
