import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

export const firebaseConfig = {
  apiKey: process.env.FIREBASE_APIKEY || "",
  authDomain: process.env.FIREBASE_AUTH_DOMAIN || "",
  projectId: process.env.FIREBASE_PROJECT_ID || "",
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET || "",
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID || "",
  appId: process.env.FIREBASE_APP_ID || "",
  measurementId: process.env.FIREBASE_MEASUREMENT_ID || "",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

// const app = firebase.app()
const auth = firebase.auth();
const db = firebase.firestore();
const now = firebase.firestore.Timestamp.now();

export { auth, db, now };
