import {
  useState,
  useEffect,
  useContext,
  createContext,
  useCallback,
} from "react";
import { auth, db } from "../firebase-config";
import firebase from "firebase/app";
import "firebase/auth";
import { setUserToken } from "@server-lib/utils/cookie";
import {
  User,
  UserData,
  defaultUserData,
  AuthProviderType,
  AuthContextInterface,
  AuthProviderProps,
} from "./types";

export const AUTH_ERROR_CODE = {
  NEED_VERIFICATION_EMAIL: 1001,
  WRONG_PASSWORD: "auth/wrong-password",
  NOT_FOUND_ACCOUNT: "auth/user-not-found",
};

const useAuthProvider = (u: User) => {
  const [user, setUserInfo] = useState<User | null>(u);
  const [isInitializing, setInitialize] = useState(true);

  const setUser = (u: User | null) =>
    setUserInfo(u === null ? null : { ...user, ...u });

  const createUser = async (user: firebase.User) => {
    const uData = await db.doc(`users/${user.uid}`).get();
    const oriData = uData.data();

    const data: UserData = {
      ...defaultUserData,
      _id: user.uid,
      email: user.email,
      firstName:
        oriData && !!oriData.firstName
          ? oriData.firstName
          : user.displayName.split(" ")[0],
      lastName:
        oriData && !!oriData.lastName
          ? oriData.lastName
          : user.displayName.split(" ")[1],
      avatarUrl:
        oriData && !!oriData.avatarUrl ? oriData.avatarUrl : user.photoURL,
    };
    return db.doc(`users/${user.uid}`).set(data);
  };

  const signIn = async (params: { email: string; password: string }) => {
    try {
      const { email, password } = params;
      const response = await auth.signInWithEmailAndPassword(email, password);
      return await getUserAdditionalData(response.user);
    } catch (error) {
      return { error };
    }
  };

  const signInWithGoogle = async () => {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      const result = await auth.signInWithPopup(provider);
      await createUser(result.user);
      return await getUserAdditionalData(result.user);
    } catch (error) {
      return { error };
    }
  };

  const signInWithFacebook = async () => {
    try {
      const provider = new firebase.auth.FacebookAuthProvider();
      const result = await auth.signInWithPopup(provider);
      await createUser(result.user);
      return await getUserAdditionalData(result.user);
    } catch (error) {
      return { error };
    }
  };

  const signOut = () => {
    auth.signOut();
    setUserToken("");
    setUserInfo(null);
  };

  const verifyEmail = async (params: { token: string }) => {
    try {
      const { token } = params;
      await auth.applyActionCode(token);
      return {
        data: true,
      };
    } catch (error) {
      return { error };
    }
  };

  // forgot password => send email => reset password
  const resetPassword = async (params: { token: string; password: string }) => {
    try {
      const { token, password } = params;
      await auth.verifyPasswordResetCode(token);
      await auth.confirmPasswordReset(token, password);
      return {
        data: true,
      };
    } catch (error) {
      return { error };
    }
  };

  const changePassword = async (params: {
    oldPassword: string;
    newPassword: string;
  }) => {
    try {
      const { oldPassword, newPassword } = params;
      if (auth.currentUser) {
        if (
          oldPassword &&
          newPassword &&
          oldPassword.length > 0 &&
          newPassword.length > 0
        ) {
          const credential = firebase.auth.EmailAuthProvider.credential(
            auth.currentUser.email,
            oldPassword,
          );
          await auth.currentUser.reauthenticateWithCredential(credential);
          await auth.currentUser.updatePassword(newPassword);
        }
        return {
          data: true,
        };
      } else {
        return {
          error: {
            message: "The user needs to login",
          },
        };
      }
    } catch (error) {
      return { error };
    }
  };

  const setNewPassword = async (params: { password: string }) => {
    try {
      const { password } = params;
      if (!auth.currentUser) {
        return {
          error: {
            message: "You need to login first",
          },
        };
      }
      const credential = firebase.auth.EmailAuthProvider.credential(
        auth.currentUser.email,
        password,
      );
      await auth.currentUser.linkWithCredential(credential);
      return {
        data: true,
      };
    } catch (error) {
      return { error };
    }
  };

  const updateProfile = async (params: {
    firstName: string;
    lastName: string;
  }) => {
    try {
      const { firstName, lastName } = params;
      await auth.currentUser.updateProfile({
        displayName: `${firstName} ${lastName}`,
      });
      await db.collection("users").doc(auth.currentUser.uid).set(
        {
          firstName,
          lastName,
        },
        { merge: true },
      );
      return {
        data: true,
      };
    } catch (error) {
      return { error };
    }
  };

  const linkSocialMedia = async (type: AuthProviderType) => {
    if (!auth.currentUser) {
      return {
        error: {
          message: "You need to login first",
        },
      };
    }
    try {
      let key = "";
      if (type === "GOOGLE") {
        const googleProvider = new firebase.auth.GoogleAuthProvider();
        await auth.currentUser.linkWithPopup(googleProvider);
        key = "google.com";
      } else if (type === "FACEBOOK") {
        const facebookProvider = new firebase.auth.FacebookAuthProvider();
        await auth.currentUser.linkWithPopup(facebookProvider);
        key = "facebook.com";
      } else if (type === "TWITTER") {
        const twitterProvider = new firebase.auth.TwitterAuthProvider();
        await auth.currentUser.linkWithPopup(twitterProvider);
        key = "twitter.com";
      }
      const providerData = auth.currentUser.providerData.filter(
        (t) => t.providerId === key,
      );
      if (providerData.length > 0) {
        const pData = providerData[0];
        const uData = await db.doc(`users/${auth.currentUser.uid}`).get();
        const oriData = uData.data();
        const data = {
          firstName:
            oriData && !!oriData.firstName
              ? oriData.firstName
              : pData.displayName.split(" ")[0],
          lastName:
            oriData && !!oriData.lastName
              ? oriData.lastName
              : pData.displayName.split(" ")[1],
          avatarUrl:
            oriData && !!oriData.avatarUrl ? oriData.avatarUrl : pData.photoURL,
        };
        await db
          .doc(`users/${auth.currentUser.uid}`)
          .set(data, { merge: true });
      }
      return {
        data: true,
      };
    } catch (error) {
      return { error };
    }
  };

  const getUserAdditionalData = async (user: firebase.User) => {
    try {
      const { signInProvider } = await user.getIdTokenResult();
      if (
        (user.emailVerified && signInProvider === "password") ||
        signInProvider !== "password"
      ) {
        const userData = await db.collection("users").doc(user.uid).get();
        if (userData.exists) {
          const {
            _id,
            email,
            firstName,
            lastName,
            avatarUrl,
          } = userData.data();
          const uData = {
            _id,
            email,
            firstName,
            lastName,
            avatarUrl,
          };
          setUserInfo(uData);
          return {
            data: uData,
          };
        } else {
          return {
            error: {
              message: "Your profile does not exist",
            },
          };
        }
      } else {
        signOut();
        return {
          error: {
            code: AUTH_ERROR_CODE.NEED_VERIFICATION_EMAIL,
            message: "Please verify your account first",
          },
        };
      }
    } catch (error) {
      return { error };
    }
  };

  const handleAuthStateChanged = async (user: firebase.User) => {
    if (user) {
      await getUserAdditionalData(user);
    } else {
      signOut();
    }
    setInitialize(false);
  };

  useEffect(() => {
    return auth.onIdTokenChanged(async (user) => {
      if (!user) {
        setUserInfo(null);
        setUserToken("");
      } else {
        const { signInProvider } = await user.getIdTokenResult();
        if (
          (user.emailVerified && signInProvider === "password") ||
          signInProvider !== "password"
        ) {
          const token = await user.getIdToken();
          setUserToken(token);
        }
      }
    });
  }, []);

  useEffect(() => {
    return auth.onAuthStateChanged(handleAuthStateChanged);
  }, []);

  useEffect(() => {
    if (auth.currentUser) {
      return db
        .collection("users")
        .doc(auth.currentUser.uid)
        .onSnapshot((doc) => {
          setUser(doc.data() as User);
        });
    }
  }, [auth.currentUser]);

  return {
    user,
    setUser,
    signIn,
    signInWithGoogle,
    signInWithFacebook,
    signOut,
    verifyEmail,
    resetPassword,
    changePassword,
    setNewPassword,
    updateProfile,
    linkSocialMedia,
    isInitializing,
  };
};

const AuthContext = createContext<AuthContextInterface>(null);

export const AuthProvider = ({ children, initialUser }: AuthProviderProps) => {
  const provider = useAuthProvider(initialUser);

  const hasAuthProvider = useCallback(
    (type: AuthProviderType) => {
      if (!auth.currentUser) return null;
      let key = "";
      switch (type) {
        case "GOOGLE":
          key = "google.com";
          break;
        case "EMAIL_PASSWORD":
          key = "password";
          break;
        case "FACEBOOK":
          key = "facebook.com";
          break;
        // TO DO: twitter
      }
      return (
        auth.currentUser.providerData.filter((t) => t.providerId === key)
          .length > 0
      );
    },
    [auth.currentUser],
  );

  useEffect(() => {
    if (initialUser) provider.setUser(initialUser);
  }, [initialUser]);

  return (
    <AuthContext.Provider
      value={{ ...provider, firebaseUser: auth.currentUser, hasAuthProvider }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
