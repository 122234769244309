import React from "react";
import NextLink, { LinkProps } from "next/link";
import { useAuth } from "@components/app";

export interface AuthLinkProps extends LinkProps {
  auth?: boolean;
}

export default function AuthLink({
  href,
  auth = true,
  ...props
}: React.PropsWithChildren<AuthLinkProps>) {
  const { user } = useAuth();

  return (
    <NextLink
      href={
        user === null && auth === true
          ? "/login?redirect=" +
            (typeof href === "string" ? href : href.pathname)
          : href
      }
      {...props}
    />
  );
}
